<template>
  <div class="animated fadeIn">
    <b-row class="justify-content-md-center">
      <b-col sm="8">
        <b-form @submit.stop.prevent="onSubmit" @reset="onReset" v-if="show">
          <b-card header-tag="header" footer-tag="footer">
            <loading :active.sync="isLoading" 
              :is-full-page="false"
              :color="this.$config.LOADER.color"
              :opacity="this.$config.LOADER.opacity"
              :background-color="this.$config.LOADER.backgroundColor"
              :z-index="10000"></loading>
            <h5 slot="header" class="mb-0">
              <span>Нууц үг солих</span>
            </h5>
              <b-form-group id="input-group-oldPassword" label="Хуучин нууц үг:" label-for="input-oldPassword"
              >
                <b-form-input
                  id="input-oldPassword"
                  v-model="form.oldPassword"
                  type="password"
                  required
                  placeholder="Хуучин нууц үг"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-password" label="Шинэ нууц үг:" label-for="input-password">
                <b-form-input
                  id="input-password"
                  type="password"
                  v-model="form.password"
                  required
                  placeholder="Шинэ нууц үг"
                  minlength="6"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-password_confirmation"  label="Нууц үг давт:" label-for="input-password_confirmation">
                <b-form-input
                  type="password"
                  id="input-password_confirmation"
                  v-model="form.password_confirmation"
                  required
                  placeholder="Нууц үг давт"
                ></b-form-input>
                <b-form-invalid-feedback :state="validation">
                  Нууц үг таарахгүй байна
                </b-form-invalid-feedback>
              </b-form-group>
            
              <div slot="footer">
                <b-button type="submit" variant="primary">Хадгалах</b-button>
                <b-button type="reset" variant="danger" class="ml-3">Шинэчлэх</b-button>
              </div>
            </b-card>
          </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>


export default {
  name: 'ChangePassword',
  data: function() {
    return {
      isLoading: false,
      form: {
          oldPassword: '',
          password: '',
          password_confirmation: '',
      },
      show: true,
    }
  },
  computed: {
    validation() {
      return this.$data.form.password == this.$data.form.password_confirmation || this.$data.form.password_confirmation == ''
    }
  },
  created: function() {
    this.checkSession();
  }, 
  methods: {
    onSubmit(evt) {
      if (this.$data.form.password == this.$data.form.password_confirmation) {
        this.isLoading = true
        
        this.$data.form.token = this.$store.getters.token
        this.$data.form.email = this.$store.getters.email
        this.$data.form.id = this.$store.getters.user.id

        this.$http.post(
          this.$config.API_URL + 'AdminWebService/change_password_sys_user', 
          { data: JSON.stringify(this.$data.form)},
          { headers: this.$store.getters.httpHeader, emulateJSON: true }
          ).then(
            response => {
              this.isLoading = false;
              if (response.body.responseResultType == "SUCCESS") {
                  this.showToast('Амжилттай', 'Амжилттай хадгалагдлаа', 'success')
                  this.onReset(evt)
              } else {
                let _messages = response.body.failureMessages.message
                for(let i in _messages) {
                  this.showToast('Анхаар', _messages[i].failureMessage, 'warning')
                }
              }
              
            }, 
            response => {
              this.isLoading = false;
            }
          );
      } else {
        this.showToast('Анхаар', 'Нууц үг таарахгүй байна', 'warning')
      }
      
    },
    onReset(evt) {
      evt.preventDefault()
      this.$data.form = {
          oldPassword: '',
          password: '',
          password_confirmation: '',
      }
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>
